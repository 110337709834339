<template>
    <div :class="['layout', classList, {'pb-[40px]': includeBottomPadding}]">
        <transition name="fade">
            <CookieBanner
                v-if="showCookieBanner"
                @accept-cookie="acceptCookies"
                @reject-cookie="rejectCookies"
            />
        </transition>
        <slot />
    </div>
</template>

<script>
import AOS from 'aos'
import Cookies from 'js-cookie'
import CookieBanner from '@/Components/Global/CookieBanner.vue'

export default {
    components: {CookieBanner},
    props: {
        classList: {
            type: String,
            default: 'bg-[#2D2D2F]',
        },
        includeBottomPadding: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            campaignData: null,
            showCookieBanner: false,
        }
    },
    created() {
        const accepted = Cookies.get('cookiesAccepted')
        let rejectedDate = ''

        if (typeof localStorage !== 'undefined') {
            rejectedDate = localStorage.getItem('cookiesRejectedDate')
        } else {
            console.warn('localStorage is not available')
        }

        if (!accepted) {
            if (rejectedDate) {
                const oneWeekInMs = 7 * 24 * 60 * 60 * 1000
                const rejectedTime = new Date(rejectedDate).getTime()
                const currentTime = new Date().getTime()

                if (currentTime - rejectedTime > oneWeekInMs) {
                    this.showCookieBanner = true
                    localStorage.removeItem('cookiesRejectedDate')
                }
            } else {
                this.showCookieBanner = true
            }
        }
    },
    mounted() {
        AOS.init()
        this.extractCampaignData()
    },
    methods: {
        getQueryParams(url) {
            const params = {}
            const queryString = url.split('?')[1] || ''
            const pairs = queryString.split('&')

            pairs.forEach((pair) => {
                const [key, value] = pair.split('=')
                if (key && value) {
                    params[key] = decodeURIComponent(value)
                }
            })

            return params
        },
        extractCampaignData() {
            const currentUrl = window.location.href
            const queryParams = this.getQueryParams(currentUrl)

            this.campaignData = {
                campaign_name:
                    queryParams['campaign_name'] || queryParams['adgroupid'],
                campaign_id:
                    queryParams['campaign_id'] || queryParams['utm_campaign'],
                ad_name: queryParams['ad_name'] || queryParams['utm_content'],
                utm_source: queryParams['utm_source'],
            }

            const identifyEvent = new amplitude.Identify()

            identifyEvent.set('environment', import.meta.env.VITE_APP_ENV)
            identifyEvent.set('adGroup', this.campaignData.campaign_name)
            identifyEvent.set('campaign', this.campaignData.campaign_id)
            identifyEvent.set('creative', this.campaignData.ad_name)
            identifyEvent.set('network', this.campaignData.utm_source)
            identifyEvent.set('platform', 'web')

            amplitude.identify(identifyEvent)
        },
        acceptCookies() {
            Cookies.set('cookiesAccepted', 'true', {expires: 365})
            this.showCookieBanner = false
        },
        rejectCookies() {
            Cookies.remove('cookiesAccepted')
            localStorage.setItem(
                'cookiesRejectedDate',
                new Date().toISOString()
            )
            this.showCookieBanner = false
        },
    },
}
</script>

<style lang="scss" scoped>
.layout {
    @apply min-h-full flex flex-col relative;
}

.fade-enter-from,
.fade-leave-to {
    @apply opacity-0;
}

.fade-enter-to,
.fade-leave-from {
    @apply opacity-100;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
</style>
