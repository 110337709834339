<template>
    <div class="cookie-banner">
        <p class="cookie-banner__title">We use cookies!</p>
        <div class="cookie-banner__description">
            Hi, CHATOPEN uses essential cookies to ensure its proper operation
            and tracking cookies to understand how you interact with it. The
            latter will be set only after consent.
        </div>
        <div class="cookie-banner__buttons">
            <button type="button" class="cookie-banner__button accept-button" @click="$emit('accept-cookie')">
                Accept All
            </button>
            <button type="button" class="cookie-banner__button reject-button" @click="$emit('reject-cookie')">
                Reject All
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.cookie-banner {
    @apply fixed z-[1000] bottom-[24px] right-[50%] max-w-[348px] w-full px-[20px] py-[24px] rounded-[20px] bg-[#3E3E40];
    transform: translate(50%, -20%);

    @screen lg {
        @apply bottom-[24px] right-[24px];
        transform: translate(0, 0);
    }
}

.cookie-banner__title {
    @apply mb-[14px] font-[600] text-[16px] leading-[19px] text-[#FFFFFF];
}

.cookie-banner__description {
    @apply mb-[16px] font-[500] text-[14px] leading-[18px] text-[#FFFFFF];
}

.cookie-banner__buttons {
    @apply flex justify-between gap-[12px];
}

.cookie-banner__button {
    @apply w-full py-[10px] rounded-[16px] font-[500] text-[16px] leading-[19px] text-[#FFFFFF];
}

.accept-button {
    @apply bg-[#0074E4];
    &:hover {
        @apply bg-[#0052A1];
    }
}

.reject-button {
    @apply bg-[#696B6C];
    &:hover {
        @apply bg-[#5D5D5D];
    }
}
</style>
