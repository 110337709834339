function trackEvent(eventName, eventData = null, source = null) {
    if (typeof gtag === 'function' && source === 'google') {
        gtag('event', eventName, eventData)
    }

    if (typeof fbq === 'function' && source === 'fb') {
        fbq('track', eventName, eventData)
    }

    if (typeof amplitude === 'object' && !source) {
        amplitude.track(eventName, {
            ...eventData,
        })
    }
}

export {trackEvent}
